/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {
  getEmployee(data) {
    return api.get(`employee/list?search=${data.search}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },

  all() {
    return api.get(`employee/get_all`)
  },

  showEmployee(data){
    return api.get(`/order?search=${data.search}&date=${data.date}&status=${data.OrderStatus}`)
  },

  employeeStore(data) {
    let getFormData = data

    let params = new FormData();
    params.append('email',getFormData.employee_email)
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`employee/store`, params, config)
  },

  employeeUpdate(data) {
    let getFormData = data

    let params = new FormData();
    params.append('_method','PUT')
    params.append('email',getFormData.employee_email)
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        if (getFormData[key]) {
          params.append(key, getFormData[key]);
        }
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`employee/update/${data.employee_id}`, params, config)
  },

  employeeUpdateStatus(id) {
    // let getFormData = data

    let params = new FormData();
    params.append('_method','PUT')
    // for (var key in getFormData) {
    //   if (getFormData.hasOwnProperty(key)) {
    //     params.append(key, getFormData[key]);
    //   }
    // }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.post(`employee/update_status_employee/${id}`, params, {})
  },


  employeeDestroy(id) {
    // let getFormData = data

    let params = new FormData();
    // for (var key in getFormData) {
    //   if (getFormData.hasOwnProperty(key)) {
    //     params.append(key, getFormData[key]);
    //   }
    // }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.delete(`employee/delete/${id}`, params, {})
  },


}
