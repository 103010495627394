<template>
  <div>
    <v-row>
      <v-col
        cols="6"
        sm="6"
        md="6">
        <v-card>
          <v-card-text>
            <Datepicker
              readonly
              style="width: 100%"
              v-model="date"
              value-type="format"
              format="YYYY-MM-DD"
              placeholder="วันที่เบิก"
              label="วันที่เบิก"
            ></Datepicker>

            <v-text-field
              class="mt-5"
              v-model="status"
              readonly
              label="สถานะ"
            >
            </v-text-field>

            <v-select
              :readonly="id != ''"
              outlined
              dense
              class="mb-5"
              hide-details
              v-model="editedItem.employee_id"
              :items="employees"
              item-text="employee_name"
              item-value="employee_id"
              label="ผู้ดำเนินรายการขอเบิก"
            ></v-select>
            <small
              class="validate-err"
              v-for="(err,index) in v$.editedItem.employee_id.$silentErrors"
              v-if="v$.editedItem.employee_id.$error">{{ index > 0 ? ', ' : '' }}
              {{ err.$message }}</small>

            <label style="font-size: 11px" v-if="editedItem.approve_at" for="department">ทำรายการ ณ
              วันที่ : {{ editedItem.created_at }} </label>

            <div class="form-group" v-if="editedItem.approve_by">
              <label for="department">พนักงานอนุมัติและตัดยอด</label> :
              <span class="badge badge-success"> {{  editedItem.approve ? editedItem.approve.employee_name : '-' }} </span> <br>
              <label style="font-size: 11px" v-if="editedItem.approve_at" for="department">ทำรายการ
                ณ วันที่ : {{ editedItem.approve_at }} </label>
            </div>

          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="6"
        sm="6"
        md="6">
        <v-card>
          <v-card-text>
            <v-row>
              <v-select
                :readonly="id != ''"
                v-model="product_id"
                :items="products"
                outlined
                dense
                hide-details
                item-text="product_name"
                item-value="product_id"
                label="ค้นหาสินค้า"
                @change="select_product"
              >
              </v-select>
            </v-row>
          </v-card-text>

        </v-card>
      </v-col>

      <v-col
        cols="6"
        sm="6"
        md="6">
      </v-col>

      <v-col
        v-if="editedItem.stock_employees.length > 0"
        cols="6"
        sm="6"
        md="6">
        <v-card style="margin-top: -150px;">
          <v-card-text class="mb-5">
            <v-btn

              v-if="editedItem.stock_employees.length > 0 && !editedItem.id"
              style="width: 100%"
              class="primary"
              @click="save('N')"
            >สร้างใบเบิกสินค้า
            </v-btn>

            <v-btn
              v-if="editedItem.stock_employees.length > 0 && editedItem.id"
              style="width: 100%"
              class="primary mb-5"
              :disabled="editedItem.status === 'Y' ||  editedItem.ready === 'Y' || editedItem.status === 'C'"
              @click="save('N')"
            >แก้ไขเบิกสินค้า
            </v-btn>


            <v-btn
              style="width: 100%"
              color="secondary"
              v-if="editedItem.id"
              @click="print()"
              type="button" aria-pressed="true">
              <span>พิมพ์ใบบิกสินค้า</span>
              <!--              :href="'/admin/print_stock_employee/'+stock.id"-->
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="editedItem.stock_employees"
      :search="search"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
      </template>
      <template v-slot:item.index="{ index }">
        {{ index + 1 }}
      </template>
      <template v-slot:item.request_amount="{ item }">
        <v-text-field
          v-model="item.amount"
          type="number"
          min="0"
          :disabled="editedItem.status == 'Y'|| editedItem.status == 'C'"
          :max="parseInt(item.amount_stock_employees) ? parseInt(item.amount_stock_employees) : parseInt(item.product_amount)"
        ></v-text-field>

        <!--        <small-->
        <!--          class="validate-err"-->
        <!--          v-for="(err,index) in v$.item.amount.$silentErrors"-->
        <!--          v-if="v$.item.amount.$error">{{ index > 0 ? ', ' : '' }} {{ err.$message }}</small>-->

      </template>
      <template v-slot:item.type="{ item }">
        <span v-if="item.product && item.product.type_product == '1'">ออนไลน์</span>
        <span v-if="item.product && item.product.type_product == '2'">ภายใน</span>
      </template>
      <template v-slot:item.unit="{ item }">
        ชิ้น
      </template>
      <template v-slot:item.value="{ item }">
        <span>
          {{
            item.product && item.product.product_costs && item.amount ? parseFloat(item.product.product_costs * item.amount).toFixed(2) : 0
          }}

          <!--          //     @if(-->
          <!--//       isset(auth()->user()->role()['all']) && auth()->user()->role()['all'] ||-->
          <!--// isset(auth()->user()->role()['view_product_price']) && auth()->user()->role()['view_product_price'])-->
          <!--// @{{parseFloat(product.product_costs*product.amount).toFixed(2)}}-->
          <!--// @else-->
          <!--// - -->
          <!--// @endif-->
        </span>
      </template>
      <template v-slot:item.actions="{ index }">
        <!--      <v-icon-->
        <!--        small-->
        <!--        class="mr-2"-->
        <!--        @click="editItem(item)"-->
        <!--      >-->
        <!--        {{ icons.mdiPencil }}-->
        <!--      </v-icon>-->

        <v-icon
          small
          v-if="!editedItem.status !== 'Y' &&  editedItem.status !== 'C'"
          @click="deleteItem(index)"
        >
          {{ icons.mdiDelete }}
        </v-icon>
      </template>
      <template v-slot:no-data>
        <!-- <v-btn
          color="primary"
          @click="initialize"
        >
          Reset
        </v-btn> -->
      </template>
    </v-data-table>

    <span v-if="editedItem.ready === 'N'">
      <v-btn
        class="mt-5"
        color="primary"
        @click="cutStock()"
        :disabled="editedItem.status === 'Y'"
        style="width: 100%"
      >ยืนยันการตัดสต๊อค</v-btn>
    </span>
    <span v-if="editedItem.ready !== 'Y' && editedItem.ready !== 'N'">
      <v-btn
        v-if="editedItem.id"
        class="mt-5"
        color="primary"
        @click="save('Y')"
        style="width: 100%"
        :disabled="editedItem.status === 'Y'|| editedItem.status === 'C'">
<!--      <span v-if="editedItem.status === 'N'">พร้อมตัดสต๊อค</span>-->
      <span v-if="editedItem.status === 'Y'">ตัดสต๊อคเรียบร้อย</span>
      <span v-if="editedItem.status === 'C'">ยกเลิก/คืนยอดเรียบร้อย</span>
    </v-btn>
    </span>
  </div>
</template>

<script>
import {mdiPencil, mdiDelete,} from '@mdi/js'
import Datepicker from 'vue2-datepicker';
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_stock_employee from '../../../services/stockEmokoyee'
import instance_product from '../../../services/product'
import instance_employee from '../../../services/employee'
import Swal from "sweetalert2";
import {minValue, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";

export default {
  components: {Datepicker},
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    id: '',
    products: [],
    product_id: null,
    employees: [],
    dialog: false,
    dialogDelete: false,
    date: '2023-02-15',
    search: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index',
        sortable: false
      },
      {text: 'รหัสสินค้า', value: 'product.product_code', sortable: false},
      {text: 'รายการ', value: 'product.product_name', sortable: false},
      {text: 'หมวดหมู่', value: 'product.product_type.product_type_name', sortable: false},
      {text: 'คลังสินค้า', value: 'type', sortable: false},
      {text: 'จำนวนคงเหลือ', value: 'product_amount', sortable: false},
      {text: 'ราคา', value: 'product.product_costs', sortable: false},
      {text: 'หน่วย', value: 'unit', sortable: false},
      {text: 'จำนวนที่ขอเบิก', value: 'amount_stock_employees', sortable: false},
      {text: 'จำนวนที่เบิกได้', value: 'request_amount', sortable: false},
      {text: 'มูลค่า', value: 'value', sortable: false},
      {text: 'แก้ไขข้อมูล', value: 'actions', sortable: false},
    ],

    status: 'สร้างใบเบิกสินค้า',
    desserts: [],
    editedIndex: -1,
    editedItem: {
      employee_type_name: '',
      employee_id: '',
      stock_employees: [],
    },
    defaultItem: {
      employee_type_name: '',
      employee_id: '',
      stock_employees: [],
    },
  }),

  validations() {
    return {
      editedItem: {
        employee_id: {required},
        stock_employees: {
          required,
          $each: {
            amount: {
              required,
            }
          }
        }
      }
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม กลุ่มพนักงาน' : 'แก้ไข กลุ่มพนักงาน'
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    // this.getEmployeeType()

    this.id = this.$route.params.id
    if (this.id && this.id != undefined) {
      this.initData()
    } else {
      this.id = '';
    }

    this.initProduct()
    this.initEmployee()

    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    this.date = `${year}-${String(month).padStart(2, '0')}-${day}`;
  },

  methods: {
    print() {
      var url = `/print_stock_employee/${this.editedItem.id}`
      window.open(url, '_blank', 'noreferrer');
    },
    cutStock() {
      // $('button').prop('disabled', true);
      /*  Object.entries(this.data).forEach(([key, val]) => {
            if( !this.data[key].status || this.data[key].status === 'N' || this.data[key].amount === 0 || this.data[key].product_amount === '0'){
                swal("โปรดตรวจสอบรายการเบิกสินค้า");
                check = false
                return false
            }
        });*/

      Swal.fire({
        title: 'ยืนยันการเบิกสินค้า ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        buttons: ["ยกเลิก", "ยืนยัน"],
        dangerMode: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var data = {
            id: this.editedItem.id,
            data: JSON.stringify(this.editedItem.stock_employees),
            approve_by: '',
            cut_stock: 'Y'
          }
          instance_stock_employee.stockEmployeeCutStock(data).then(res => {

            Swal.fire({
              icon: 'success',
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500
            })
            // swal({
            //   text: 'เบิกสินค้าสำเสร็จ',
            //   timer: 3000,
            //   icon: 'success',
            //   showConfirmButton: false,
            //   button: false
            // })
            location.reload();
            // this.$router.push({name: 'pages-stock-employee-edit', params: {id: id}})
          }).catch(err => {
            Swal.fire({
              icon: 'error',
              title: 'เกิดข้อผิดพลาด !!',
              text: err.message,
            })

            // swal({
            //   text: error.message,
            //   timer: 3000,
            //   icon: 'error',
            //   showConfirmButton: false,
            //   button: false
            // })
            // console.log(error.message);
          })
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'แน่ใจเเล้วสามารถยืนยันการสั่งซื้อได้ทันที',
            showConfirmButton: false,
            timer: 1500
          })
        }
      })


    },
    formatDate(d) {
      // you could also provide your own month names array
      const months = this.$refs.datePicker.translation.months
      return `${d.getDate().toString().padStart(2, 0)} ${months[d.getMonth()]} ${d.getFullYear()}`
    },
    initData() {
      this.$store.state.isLoading = true
      instance_stock_employee.show(this.id).then(res => {
        this.editedItem = res.data.stock;
        this.editedItem.stock_employees = res.data.data;


        if (this.editedItem && this.editedItem.status && this.editedItem.status === 'N') {
          this.status = 'รอทำเบิกสินค้า'
        }
        if (this.editedItem && this.editedItem.status && this.editedItem.status === 'Y') {
          this.status = 'เบิกสินค้าเรียบร้อย'
        }
        if (this.editedItem && this.editedItem.status && this.editedItem.status === 'C') {
          this.status = 'ยกเลิก/คืนยอดเรียบร้อย'
        }

        if (this.editedItem.created_at){
          this.date = this.editedItem.created_at
        }
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      })
    },
    initProduct() {
      this.$store.state.isLoading = true
      var data = {
        phone_brand_id: 2,
        product_type_id: 2,
        member_type_id: 1,
      };
      instance_product.getProduct(data).then(res => {
        this.products = res.data
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
    initEmployee() {
      this.$store.state.isLoading = true
      instance_employee.all().then(res => {
        this.employees = res.data.data
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
    select_product() {
      // find index

      var index = false
      var product = null;
      Object.entries(this.products).forEach(([key, val]) => {
        if (this.products[key].product_id === this.product_id) {
          // this.data[key].amount = this.data[key].amount + 1
          // check = false
          index = key;
          product = this.products[key];
          return false
        }
      });


      if (index) {
        if (this.product_id && this.product_id.product_amount != 0) {
          // var product = {
          //   'product_code': this.product_select.product_code,
          //   'product_costs': this.product_select.product_costs,
          //   'product_price': this.product_select.product_price,
          //   'type': this.product_select.type,
          //   'product_id': this.product_select.product_id,
          //   'name': this.product_select.name,
          //   'productSelect': this.product_select.productSelect,
          //   'product_amount': parseInt(this.product_select.product_amount),
          //   'amount': 1
          // }

          var tmp = {
            product_id: product.product_id,
            product_amount: product.product_amount,
            product: product
          }
          this.editedItem.stock_employees.push(tmp);
        } else {

          Swal.fire({
            icon: 'error',
            title: 'จำนวนสินค้าไม่เพียงพอ',
            showConfirmButton: false,
            timer: 1500
          })
          // swal({
          //   text: ' จำนวนสินค้าไม่เพียงพอ',
          //   timer: 5000,
          //   icon: 'error',
          //   showConfirmButton: false,
          //   button: false
          // })
        }
      }
      ///////////////
      this.product_id = null;
    },
    editItem(item) {
      this.editedIndex = this.employees_types.indexOf(item)
      this.editedItem = {...item}
      this.dialog = true
    },

    deleteItem(index) {
      this.editedItem.stock_employees.splice(index, 1)

      // this.editedIndex = this.employees_types.indexOf(item)
      // this.editedItem = {...item}
      // this.dialogDelete = true
    },

    deleteItemConfirm() {
      instance_stock_employee.employeeTypeDestroy(this.editedItem).then(res => {
        Swal.fire({
          icon: 'success',
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500
        })

        this.desserts.splice(this.editedIndex, 1)
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })


      })
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    async save(ready = 'N') {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.$store.state.isLoading = true

      var data = {
        date : this.date,
        stock_id: this.editedItem.id,
        employee_id: this.editedItem.id ? this.editedItem.employee_id.employee_id : this.editedItem.employee_id,
        // order_inv_array : [],
        data: JSON.stringify(this.editedItem.stock_employees),
        ready: ready,
      }

      if (this.editedItem.id) {
        var check = true;
        if (ready == 'Y') {
          Swal.fire({
            title: 'พร้อมตัดสต๊อคแล้ว ใช่หรือไม่ ?',
            text: "เตือน คุณจะไม่สามารถแก้ไขข้อมูลได้อีก",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            buttons: ["ยกเลิก", "ยืนยัน"],
          }).then((result) => {
            if (result.isConfirmed) {
              instance_stock_employee.stockEmployeeUpdate(data)
                .then(res => {
                  Swal.fire({
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 1500
                  })
                  // $this.stock_number = response.data.no_stock;
                  // $this.date = response.data.created_at;
                  // $this.status = 'รอทำการเบิกสินค้า';

                  // location.reload();
                  // this.close()
                  this.close()
                  this.$store.state.isLoading = false
                  this.$router.push({name: 'pages-stock-employee'})
                }).catch(err => {
                Swal.fire({
                  icon: 'error',
                  title: 'เกิดข้อผิดพลาด !!',
                  text: err.message,
                })
                this.$store.state.isLoading = false
              });

            } else {
              this.$store.state.isLoading = false
            }
          })
          // ต้อง confirm ก่อน

        }else{
          instance_stock_employee.stockEmployeeUpdate(data)
            .then(res => {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
              // $this.stock_number = response.data.no_stock;
              // $this.date = response.data.created_at;
              // $this.status = 'รอทำการเบิกสินค้า';

              // location.reload();
              // this.close()
              this.$store.state.isLoading = false
              this.$router.push({name: 'pages-stock-employee'})
            }).catch(err => {
            Swal.fire({
              icon: 'error',
              title: 'เกิดข้อผิดพลาด !!',
              text: err.message,
            })
            this.$store.state.isLoading = false
          });
        }
      } else {
        instance_stock_employee.stockEmployeeStore(data)
          .then(res => {
            Swal.fire({
              icon: 'success',
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500
            })
            this.close()
            console.log(222)
            this.$store.state.isLoading = false
            this.$router.push({name: 'pages-stock-employee'})
          }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          console.log(333)
          this.$store.state.isLoading = false
        });
      }
    },
    getEmployeeType() {
      this.$store.state.isLoading = true
      var data = {
        search: '',
        date: '',
        status: '',
      }

      instance_stock_employee.getEmployeeType(data).then(res => {
        this.employees_types = res.data.data;
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
