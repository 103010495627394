import api from '../axios.service'

export default {
  get(data) {
    return api.get(`stock_employee/list?search=${data.search}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },
  // eslint-disable-next-line camelcase
  show(id) {
    // eslint-disable-next-line camelcase
    return api.get(`stock_employee/show/${id}`)
  },

  cancel(id) {
    // eslint-disable-next-line camelcase
    let params = new FormData();
    params.append('_method','PUT')
    // for (var key in getFormData) {
    //   if (getFormData.hasOwnProperty(key)) {
    //     params.append(key, getFormData[key]);
    //   }
    // }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.post(`stock_employee/cancel/${id}`,params, {})
  },
  store(data){
    let getFormData = data

    let params = new FormData();
    params.append('data', JSON.stringify(getFormData.data));
    params.append('order_inv_array', JSON.stringify(getFormData.order_inv_array));

    // data.append('employee_id', {{auth()->user()->member_id}});
    // for (var key in getFormData) {
    //   if (getFormData.hasOwnProperty(key)) {
    //     params.append(key, getFormData[key]);
    //   }
    // }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`stock_employee/store`,params,config)
  },
  stockEmployeeStore(data){
    let getFormData = data

    let params = new FormData();
    // for (var key in getFormData) {
    //   if (getFormData.hasOwnProperty(key)) {
    //     params.append(key, getFormData[key]);
    //   }
    // }
    // params.append('employee_id', getFormData.employee_id);
    // params.append('ready', getFormData.ready);
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`stock_employee/store`,params,config)
  },

  stockEmployeeUpdate(data){
    let getFormData = data

    let params = new FormData();
    params.append('_method','PUT')
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData.hasOwnProperty(key) != null && getFormData.hasOwnProperty(key) != 'null') {
        params.append(key, getFormData[key]);
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`stock_employee/update/${data.stock_id}`,params,config)
  },
  stockEmployeeCutStock(data){
    let getFormData = data
    let params = new FormData();
    params.append('_method','PUT')
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData.hasOwnProperty(key) != null && getFormData.hasOwnProperty(key) != 'null') {
        params.append(key, getFormData[key]);
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`stock_employee/cut_stock/${data.id}`,params,config)
  },

  stockEmployeeDestroy(id){
    let params = new FormData();

    return api.delete(`stock_employee/delete/${id}`,params, {})
  },

  getStockEmployeeReport(data){

    return api.get(`stock_employee/print_stock_employee/${data.stock_id}`)
  }


}
