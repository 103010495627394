/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {
  get(data) {
    return api.get(`product/list?search=${data.search}&date=${data.date}&status=${data.status}&status=${data.status}&type=${data.type}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },
  getReportProduct(data) {
    return api.get(`report/get_product?search=${data.search}&date=${data.date}&status=${data.status}&status=${data.status}&type=${data.type}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },
  getReportProductOutOfStock(data) {
    return api.get(`report/get_out_of_stock?search=${data.search}&date=${data.date}&status=${data.status}&type=${data.type}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },
  getReportProductLeft(data) {
    return api.get(`report/get_product_left?search=${data.search}&date=${data.date}&status=${data.status}&status=${data.status}&type=${data.type}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },
  getProductBill(data) {
    return api.get(`product/user_product?member_type_id=&product_type_id=${data.product_type_id}&productType=${data.productType}`)
  },
  getUserProduct(data) {
    const url = `user/check_product?search=${data.search}&phone_brand_id=${data.brand_id}&phone_model_id=${data.model_id}&page=${data.page}`

    return api.get(url)
  },
  show(id) {
    return api.get(`product/show/${id}`)
  },
  getProductAmount(id) {
    return api.get(`product/product_amount?id=${id}`)
  },
  getProduct(data) {
    return api.get(`api_get_product?phone_brand_id=${data.phone_brand_id}&product_type_id=${data.product_type_id}`)
  },
  // eslint-disable-next-line camelcase
  getProductByType(product_type_id, phone_brand_id, member) {
    // eslint-disable-next-line camelcase
    return api.get(`/apiProduct?product_type_id=${product_type_id}&phone_brand_id=${phone_brand_id}&member_type_id=${member.member_type_id}`)
  },
  getProductsByType(product_type_ids, phone_brand_ids, member) {
    // eslint-disable-next-line camelcase
    return api.get(`/apiProducts?product_type_ids=${product_type_ids}&phone_brand_ids=${phone_brand_ids}&member_type_id=${member.member_type_id}`)
  },

  update(data) {
    const getFormData = data

    const params = new FormData()
    params.append('_method', 'PUT')
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key] && getFormData[key] != 'null') {
        params.append(key, getFormData[key])
      }
    }

    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post(`product/update/${data.product_id}`, params, config)
  },
  store(data) {
    const getFormData = data

    const params = new FormData()
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post('product/store', params, config)
  },

  destroy(id) {
    // let getFormData = data

    const params = new FormData()

    // // params.append('_method', 'DELETE');
    // for (var key in getFormData) {
    //   if (getFormData.hasOwnProperty(key)) {
    //     params.append(key, getFormData[key]);
    //   }
    // }

    return api.delete(`product/delete/${id}`, params, {})
  },
  destroySelect(ids) {
    const params = new FormData()

    // params.append('_method', 'DELETE');
    // for (var key in getFormData) {
    //   if (getFormData.hasOwnProperty(key)) {
    //     params.append(key, getFormData[key]);
    //   }
    // }
    for (let i = 0; i < ids.length; i++) {
      params.append(`ids[${i}]`, ids[i])
    }

    return api.post('product/delete_select', params, {})
  },

  updateStatus(id) {
    // let getFormData = data

    const params = new FormData()
    params.append('_method', 'PUT')

    // for (var key in getFormData) {
    //   if (getFormData.hasOwnProperty(key)) {
    //     params.append(key, getFormData[key]);
    //   }
    // }

    return api.post(`product/update_status/${id}`, params, {})
  },
}
